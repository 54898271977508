<template>
  <b-modal
    id="modalCloseContestation"
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    centered
    size="md"
    :title="$t('payments.titles.closeContestation')"
    :ok-title="$t('payments.buttons.confirmCloseContestation')"
    :ok-disabled="!enableOkButton"
    :cancel-title="$t('payments.buttons.cancel')"
    @ok="confirm"
    @hidden="handleClose"
  >
    <b-container class="p-0">
      <div slot="modal-body" class="col-12">
        <div class="row">
          <p class="titles font-weight-bold">{{ $t('payments.labels.invoiceNumber') }}:</p>
          <p id="clip-invoice" class="texts pl-2">
            {{ contestation.invoice_id }}
          </p>
        </div>
        <div class="row">
          <p class="titles font-weight-bold">{{ $t('payments.labels.contestationOpenAt') }}:</p>
          <p id="clip-invoice" class="texts pl-2">
            {{ createdAt }}
          </p>
        </div>

        <div class="row">
          <label class="titles font-weight-bold">{{ $t('payments.labels.contestationOpenBy') }}:</label>
          <p class="texts pl-2">
            {{ contestation.opened_by }}
          </p>
        </div>
        <div class="row">
          <p class="titles font-weight-bold">{{ $t('payments.labels.contestationReason') }}:</p>
          <div class="comments">
            {{ contestation.opening_comments }}
          </div>
        </div>
        <div class="row pt-2">
          <p class="titles font-weight-bold">{{ $t('payments.labels.contestationReasonForClosing') }}:</p>
           <b-form-textarea id="closingReason" v-model="closingReason" :placeholder="$t('payments.inputs.placeholder.contestationReasonForClosing')" type="text" :rows="4" :max-rows="7" :maxlength="500" />
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import CLOSE_CONTESTATION from '@graphql/payment/mutations/finish-contestation.gql';

export default {
  name: 'ModalCloseContestation',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    transactionId: {
      Type: String,
    },
    driverId: {
      Type: String,
    },
    contestation: {
      type: Object,
    },
  },
  data() {
    return { closingReason: '' }
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
    enableOkButton: function () {
      return this.closingReason.length > 2;
    },
    createdAt: {
      get() {
        let utc = this.$moment.utc(this.contestation.created_at);
        const userTimezone = this.$store.getters['user/entityRegion']?.country === 'MX'
                                    ? 'America/Mexico_City'
                                    : 'America/Sao_Paulo';
        return this.$moment.tz(utc, userTimezone).format('DD/MM/YYYY HH:mm');
      },
      set(value) {
        this.contestation.created_at = value;
      },
    },
  },
  methods: {
    confirm() {
      this.closeContestation()
        .then(() => {
          this.$emit('successCloseContestation');
          this.$emit('closeModalCloseContestation');
        })
        .catch(() => {
          this.$emit('errorCloseContestation');
        });
    },
    handleClose() {
      this.$emit('change', false);
    },
    closeContestation() {
      return this.$apollo.mutate({
        mutation: CLOSE_CONTESTATION,
        variables: {
          id: this.contestation.id,
          input: {
            driver_id: this.driverId,
            transaction_id: this.transactionId,
            invoice_id: this.contestation.invoice_id,
            closing_comments: this.closingReason
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.lines {
  padding-bottom: 30px;
}
.titles {
  margin-bottom: 8px;
  font-size: 14px;
  color: #000000;
}
.texts {
  font-size: 14px;
  color: #000000;
}
.kind {
  color: #25aad6;
}
.comments {
  background-color: #eaedf3 !important;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 76px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalCloseContestation",
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        centered: "",
        size: "md",
        title: _vm.$t("payments.titles.closeContestation"),
        "ok-title": _vm.$t("payments.buttons.confirmCloseContestation"),
        "ok-disabled": !_vm.enableOkButton,
        "cancel-title": _vm.$t("payments.buttons.cancel"),
      },
      on: { ok: _vm.confirm, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c("b-container", { staticClass: "p-0" }, [
        _c(
          "div",
          {
            staticClass: "col-12",
            attrs: { slot: "modal-body" },
            slot: "modal-body",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("p", { staticClass: "titles font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.invoiceNumber")) + ":"),
              ]),
              _c(
                "p",
                { staticClass: "texts pl-2", attrs: { id: "clip-invoice" } },
                [_vm._v(" " + _vm._s(_vm.contestation.invoice_id) + " ")]
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("p", { staticClass: "titles font-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("payments.labels.contestationOpenAt")) + ":"
                ),
              ]),
              _c(
                "p",
                { staticClass: "texts pl-2", attrs: { id: "clip-invoice" } },
                [_vm._v(" " + _vm._s(_vm.createdAt) + " ")]
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "titles font-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("payments.labels.contestationOpenBy")) + ":"
                ),
              ]),
              _c("p", { staticClass: "texts pl-2" }, [
                _vm._v(" " + _vm._s(_vm.contestation.opened_by) + " "),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("p", { staticClass: "titles font-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("payments.labels.contestationReason")) + ":"
                ),
              ]),
              _c("div", { staticClass: "comments" }, [
                _vm._v(" " + _vm._s(_vm.contestation.opening_comments) + " "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row pt-2" },
              [
                _c("p", { staticClass: "titles font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("payments.labels.contestationReasonForClosing")
                    ) + ":"
                  ),
                ]),
                _c("b-form-textarea", {
                  attrs: {
                    id: "closingReason",
                    placeholder: _vm.$t(
                      "payments.inputs.placeholder.contestationReasonForClosing"
                    ),
                    type: "text",
                    rows: 4,
                    "max-rows": 7,
                    maxlength: 500,
                  },
                  model: {
                    value: _vm.closingReason,
                    callback: function ($$v) {
                      _vm.closingReason = $$v
                    },
                    expression: "closingReason",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }